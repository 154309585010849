import * as React from 'react'

const localStorageKey = 'JukeboxFavorites'

const FavoritesContext = React.createContext()

export const useFavoritesContext = () => React.useContext(FavoritesContext)

export const FavoritesContextProvider = ({ children, location }) => {
  const [favorites, setFavorites] = React.useState([])

  React.useEffect(() => {
    setTimeout(() => {
      let stored
      if (location && location.search.slice(1)) {
        stored = location.search.slice(1).split('&')
        window.localStorage.setItem(localStorageKey, JSON.stringify(stored))
      } else {
        const favsFromStorage = window.localStorage.getItem(localStorageKey)
        stored = favsFromStorage ? JSON.parse(favsFromStorage) : undefined
      }
      if (stored) {
        setFavorites(stored)
      }
    }, 50)
  }, [location])

  const toggleFavorite = React.useCallback(
    (stationId) => {
      if (favorites.includes(stationId)) {
        const newFavorites = favorites.filter((fav) => fav !== stationId)
        setFavorites(newFavorites)
        if (newFavorites.length < 1) {
          window.localStorage.removeItem(localStorageKey)
        } else {
          window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(newFavorites)
          )
        }
      } else {
        const newFavorites = [...favorites, stationId]
        setFavorites(newFavorites)
        window.localStorage.setItem(
          localStorageKey,
          JSON.stringify(newFavorites)
        )
      }
    },
    [favorites, setFavorites]
  )

  const removeAllFavorites = React.useCallback(() => {
    setFavorites([])
    window.localStorage.removeItem(localStorageKey)
  }, [setFavorites])

  const contextValue = React.useMemo(
    () => ({ favorites: favorites || [], toggleFavorite, removeAllFavorites }),
    [favorites, toggleFavorite, removeAllFavorites]
  )

  return (
    <FavoritesContext.Provider value={contextValue}>
      {children}
    </FavoritesContext.Provider>
  )
}

import * as React from 'react'
import {
  AREA_CARE_PATH,
  AREA_MFA_PATH,
  AREA_MTLA_PATH,
  AREA_MTRA_PATH,
  LOCALSTORAGE_KEY_AREA,
} from '../../config/constants'
import { useLocation } from '@reach/router'
const AreaContext = React.createContext('')

export const useAreaContext = () => React.useContext(AreaContext)

export const AreaContextProvider = ({ children }) => {
  const location = useLocation()

  const area = React.useMemo(() => {
    if (typeof window !== 'undefined') {
      const paths = location.pathname.match(/^\/[^/]+/)
      if (!paths) {
        window.localStorage.removeItem(LOCALSTORAGE_KEY_AREA)
        return '/'
      }

      if (
        paths[0] === AREA_CARE_PATH ||
        paths[0] === AREA_MFA_PATH ||
        paths[0] === AREA_MTLA_PATH ||
        paths[0] === AREA_MTRA_PATH
      ) {
        window.localStorage.setItem(
          LOCALSTORAGE_KEY_AREA,
          JSON.stringify(paths[0])
        )
        return paths[0]
      } else {
        const areaRootPathFromStorage = window.localStorage.getItem(
          LOCALSTORAGE_KEY_AREA
        )
        return areaRootPathFromStorage
          ? JSON.parse(areaRootPathFromStorage)
          : '/'
      }
    } else return ''
  }, [location])

  return <AreaContext.Provider value={area}>{children}</AreaContext.Provider>
}

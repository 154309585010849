import { Link } from 'gatsby'
import React from 'react'

const HOSTNAME = process.env.HOSTNAME
const hostnameRegExp = new RegExp(`^https?://${HOSTNAME}(:d{4})?($|/.*)`)

const isInternalLink = (href) => {
  return hostnameRegExp.test(href) || href.startsWith('/')
}

export const Button = ({
  color = 'dark-blue',
  href = '',
  disabled,
  onClick,
  id,
  className,
  target = '_self',
  children,
}) => {
  const commonProps = {
    className: `button text-uppercase bg-${color} ${className}`,
    onClick: disabled ? null : onClick,
    disabled,
    id,
  }

  if (href !== '') {
    // If Button links to an EXTERNAL page
    if (!isInternalLink(href)) {
      return (
        <a {...commonProps} href={disabled ? null : href} target={target}>
          {children}
        </a>
      )
    } else {
      return (
        <Link {...commonProps} to={disabled ? null : href}>
          {children}
        </Link>
      )
    }
  } else {
    // If Button triggers an action
    return <button {...commonProps}>{children}</button>
  }
}

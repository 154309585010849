import React from 'react'
import { Link } from 'gatsby'
import { Button } from './button'

export const CookieMessage = ({ children }) => {
  const [cookie, setCookie] = React.useState(
    window.localStorage.getItem('cookies-accepted') || 'false'
  )

  const handleCookieMessage = React.useCallback(() => {
    window.localStorage.setItem('cookies-accepted', 'true')
    setCookie('true')
  }, [])

  return (
    <>
      {children}
      {cookie !== 'true' ? (
        <div className="cookie bg-blue">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-md-6 col-12">
                <p className="mb-md-0 mb-3 text-md-start">
                  <strong>Diese Website verwendet Cookies.</strong>
                  <br />
                  Nähere Informationen dazu findest du in unserer{' '}
                  <Link id="cookieLink" to="/datenschutz">
                    Datenschutzerklärung
                  </Link>
                  .
                </p>
              </div>
              <div className="col-md-6 col-12 text-md-end">
                <Button color="dark-blue" onClick={handleCookieMessage}>
                  Cookies akzeptieren
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-favoriten-js": () => import("./../../../src/pages/favoriten.js" /* webpackChunkName: "component---src-pages-favoriten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-init-js": () => import("./../../../src/pages/init.js" /* webpackChunkName: "component---src-pages-init-js" */),
  "component---src-pages-pflege-altenpflegende-js": () => import("./../../../src/pages/pflege/altenpflegende.js" /* webpackChunkName: "component---src-pages-pflege-altenpflegende-js" */),
  "component---src-pages-pflege-bereit-fuer-den-sprung-js": () => import("./../../../src/pages/pflege/bereit-fuer-den-sprung.js" /* webpackChunkName: "component---src-pages-pflege-bereit-fuer-den-sprung-js" */),
  "component---src-pages-pflege-den-wechsel-habe-ich-nie-bereut-js": () => import("./../../../src/pages/pflege/den-wechsel-habe-ich-nie-bereut.js" /* webpackChunkName: "component---src-pages-pflege-den-wechsel-habe-ich-nie-bereut-js" */),
  "component---src-pages-pflege-spielen-frage-1-js": () => import("./../../../src/pages/pflege/spielen/frage-1.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-1-js" */),
  "component---src-pages-pflege-spielen-frage-10-js": () => import("./../../../src/pages/pflege/spielen/frage-10.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-10-js" */),
  "component---src-pages-pflege-spielen-frage-11-js": () => import("./../../../src/pages/pflege/spielen/frage-11.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-11-js" */),
  "component---src-pages-pflege-spielen-frage-12-js": () => import("./../../../src/pages/pflege/spielen/frage-12.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-12-js" */),
  "component---src-pages-pflege-spielen-frage-13-js": () => import("./../../../src/pages/pflege/spielen/frage-13.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-13-js" */),
  "component---src-pages-pflege-spielen-frage-14-js": () => import("./../../../src/pages/pflege/spielen/frage-14.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-14-js" */),
  "component---src-pages-pflege-spielen-frage-15-js": () => import("./../../../src/pages/pflege/spielen/frage-15.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-15-js" */),
  "component---src-pages-pflege-spielen-frage-16-js": () => import("./../../../src/pages/pflege/spielen/frage-16.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-16-js" */),
  "component---src-pages-pflege-spielen-frage-17-js": () => import("./../../../src/pages/pflege/spielen/frage-17.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-17-js" */),
  "component---src-pages-pflege-spielen-frage-18-js": () => import("./../../../src/pages/pflege/spielen/frage-18.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-18-js" */),
  "component---src-pages-pflege-spielen-frage-19-js": () => import("./../../../src/pages/pflege/spielen/frage-19.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-19-js" */),
  "component---src-pages-pflege-spielen-frage-2-js": () => import("./../../../src/pages/pflege/spielen/frage-2.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-2-js" */),
  "component---src-pages-pflege-spielen-frage-20-js": () => import("./../../../src/pages/pflege/spielen/frage-20.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-20-js" */),
  "component---src-pages-pflege-spielen-frage-21-js": () => import("./../../../src/pages/pflege/spielen/frage-21.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-21-js" */),
  "component---src-pages-pflege-spielen-frage-22-js": () => import("./../../../src/pages/pflege/spielen/frage-22.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-22-js" */),
  "component---src-pages-pflege-spielen-frage-23-js": () => import("./../../../src/pages/pflege/spielen/frage-23.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-23-js" */),
  "component---src-pages-pflege-spielen-frage-24-js": () => import("./../../../src/pages/pflege/spielen/frage-24.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-24-js" */),
  "component---src-pages-pflege-spielen-frage-25-js": () => import("./../../../src/pages/pflege/spielen/frage-25.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-25-js" */),
  "component---src-pages-pflege-spielen-frage-26-js": () => import("./../../../src/pages/pflege/spielen/frage-26.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-26-js" */),
  "component---src-pages-pflege-spielen-frage-27-js": () => import("./../../../src/pages/pflege/spielen/frage-27.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-27-js" */),
  "component---src-pages-pflege-spielen-frage-28-js": () => import("./../../../src/pages/pflege/spielen/frage-28.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-28-js" */),
  "component---src-pages-pflege-spielen-frage-29-js": () => import("./../../../src/pages/pflege/spielen/frage-29.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-29-js" */),
  "component---src-pages-pflege-spielen-frage-3-js": () => import("./../../../src/pages/pflege/spielen/frage-3.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-3-js" */),
  "component---src-pages-pflege-spielen-frage-30-js": () => import("./../../../src/pages/pflege/spielen/frage-30.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-30-js" */),
  "component---src-pages-pflege-spielen-frage-31-js": () => import("./../../../src/pages/pflege/spielen/frage-31.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-31-js" */),
  "component---src-pages-pflege-spielen-frage-32-js": () => import("./../../../src/pages/pflege/spielen/frage-32.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-32-js" */),
  "component---src-pages-pflege-spielen-frage-33-js": () => import("./../../../src/pages/pflege/spielen/frage-33.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-33-js" */),
  "component---src-pages-pflege-spielen-frage-34-js": () => import("./../../../src/pages/pflege/spielen/frage-34.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-34-js" */),
  "component---src-pages-pflege-spielen-frage-35-js": () => import("./../../../src/pages/pflege/spielen/frage-35.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-35-js" */),
  "component---src-pages-pflege-spielen-frage-4-js": () => import("./../../../src/pages/pflege/spielen/frage-4.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-4-js" */),
  "component---src-pages-pflege-spielen-frage-5-js": () => import("./../../../src/pages/pflege/spielen/frage-5.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-5-js" */),
  "component---src-pages-pflege-spielen-frage-6-js": () => import("./../../../src/pages/pflege/spielen/frage-6.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-6-js" */),
  "component---src-pages-pflege-spielen-frage-7-js": () => import("./../../../src/pages/pflege/spielen/frage-7.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-7-js" */),
  "component---src-pages-pflege-spielen-frage-8-js": () => import("./../../../src/pages/pflege/spielen/frage-8.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-8-js" */),
  "component---src-pages-pflege-spielen-frage-9-js": () => import("./../../../src/pages/pflege/spielen/frage-9.js" /* webpackChunkName: "component---src-pages-pflege-spielen-frage-9-js" */),
  "component---src-pages-pflege-spielen-gewichtung-1-js": () => import("./../../../src/pages/pflege/spielen/gewichtung-1.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gewichtung-1-js" */),
  "component---src-pages-pflege-spielen-gewichtung-2-js": () => import("./../../../src/pages/pflege/spielen/gewichtung-2.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gewichtung-2-js" */),
  "component---src-pages-pflege-spielen-gewichtung-3-js": () => import("./../../../src/pages/pflege/spielen/gewichtung-3.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gewichtung-3-js" */),
  "component---src-pages-pflege-spielen-gewichtung-4-js": () => import("./../../../src/pages/pflege/spielen/gewichtung-4.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gewichtung-4-js" */),
  "component---src-pages-pflege-spielen-gratulation-js": () => import("./../../../src/pages/pflege/spielen/gratulation.js" /* webpackChunkName: "component---src-pages-pflege-spielen-gratulation-js" */),
  "component---src-pages-pflege-spielen-js": () => import("./../../../src/pages/pflege/spielen.js" /* webpackChunkName: "component---src-pages-pflege-spielen-js" */),
  "component---src-pages-pflege-testergebnis-js": () => import("./../../../src/pages/pflege/testergebnis.js" /* webpackChunkName: "component---src-pages-pflege-testergebnis-js" */),
  "component---src-templates-area-mfa-js": () => import("./../../../src/templates/areaMfa.js" /* webpackChunkName: "component---src-templates-area-mfa-js" */),
  "component---src-templates-area-mtla-js": () => import("./../../../src/templates/areaMtla.js" /* webpackChunkName: "component---src-templates-area-mtla-js" */),
  "component---src-templates-area-mtra-js": () => import("./../../../src/templates/areaMtra.js" /* webpackChunkName: "component---src-templates-area-mtra-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-care-js": () => import("./../../../src/templates/care.js" /* webpackChunkName: "component---src-templates-care-js" */),
  "component---src-templates-cluster-js": () => import("./../../../src/templates/cluster.js" /* webpackChunkName: "component---src-templates-cluster-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-landingpage-js": () => import("./../../../src/templates/landingpage.js" /* webpackChunkName: "component---src-templates-landingpage-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-station-js": () => import("./../../../src/templates/station.js" /* webpackChunkName: "component---src-templates-station-js" */),
  "component---src-templates-stations-js": () => import("./../../../src/templates/stations.js" /* webpackChunkName: "component---src-templates-stations-js" */)
}


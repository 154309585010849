const AREA_CARE = 'CARE'
const AREA_CARE_PATH = '/pflege'
const AREA_MFA = 'MFA'
const AREA_MFA_PATH = '/mfa'
const AREA_MTLA = 'MTLA'
const AREA_MTLA_PATH = '/mtl'
const AREA_MTRA = 'MTRA'
const AREA_MTRA_PATH = '/mtr'

const LOCALSTORAGE_KEY_AREA = 'area'

module.exports = {
  AREA_CARE,
  AREA_CARE_PATH,
  AREA_MFA,
  AREA_MFA_PATH,
  AREA_MTLA,
  AREA_MTLA_PATH,
  AREA_MTRA,
  AREA_MTRA_PATH,
  LOCALSTORAGE_KEY_AREA,
}
